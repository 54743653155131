import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faStar, faBirthdayCake, faGift } from '@fortawesome/free-solid-svg-icons';

const rewards = [
  {
    title: "Save More",
    description: "Get exclusive discounts and special offers.",
    icon: faTag, // FontAwesome icon for "Save More"
  },
  {
    title: "Loyalty Points",
    description: "Collect points on every meal and use it for additional discounts.",
    icon: faStar, // FontAwesome icon for "Loyalty Points"
  },
  {
    title: "Birthday Rewards",
    description: "Special perks on your birthday.",
    icon: faBirthdayCake, // FontAwesome icon for "Birthday Rewards"
  },
  {
    title: "Gift Points",
    description: "Gift points to your friends & family members.",
    icon: faGift, // FontAwesome icon for "Gift Points"
  },
];

const RewardProgram = () => {
  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12 animate-fadeIn">
          Reward Program Just For You!
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          {rewards.map((reward, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg hover:scale-105 transition-transform duration-300 transform animate-fadeIn hover:animate-bounce"
            >
              <div className="text-center mb-4">
                <FontAwesomeIcon
                  icon={reward.icon}
                  className="text-4xl text-green-500 animate-pulse"
                />
              </div>
              <h3 className="text-2xl font-semibold text-center mb-4">
                {reward.title}
              </h3>
              <p className="text-gray-600 text-center">
                {reward.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default RewardProgram;
