import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import RestoExp from '../img/Whyus/RestoExp.jpg';
import Customer from '../img/Whyus/Customer.jpg';

function Whyus() {
    const { ref: textRef, inView: textInView } = useInView({
        triggerOnce: true, // Trigger animation only once
        threshold: 0.1,    // Trigger when 10% of the component is visible
      });
    
      const { ref: imageRef, inView: imageInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
      });
    return (
        <div className='mx-auto mt-4 max-w-screen-lg px-4 md:px-10 h-full flex flex-col gap-y-8'>
            <motion.h1
                className="text-3xl md:text-4xl font-bold mb-6 text-center text-gray-800"
                initial={{ opacity: 0, y: -30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
            >
                Why Us?
            </motion.h1>

            {/* First Section */}
            <div className={`w-full bg-white rounded-lg shadow-lg flex flex-col md:flex-row `}>
                <div ref={imageRef} className={`w-full md:w-1/3 flex justify-center items-center  animate-slideInLeft`}>
                    <img src={RestoExp} className=' md:h-40' alt="Restaurant Experience" />
                </div>
                <div ref={textRef}className={`w-full md:w-2/3 p-4 flex flex-col justify-center ${
          textInView ? 'animate-slideInRight' : 'opacity-0 translate-x-[-100%]'
        }`}>
                    <motion.h2
                        className="text-2xl font-semibold text-gray-800 mb-4"
                        initial={{ opacity: 0, x: -30 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                    >
                        Streamlined Operations:
                    </motion.h2>
                    <div className='text-gray-700'>
                        QRapid automates the entire ordering process from the moment a customer scans the QR code to when the order reaches the kitchen. This reduces human error, speeds up service, and allows your staff to focus on what they do best—providing excellent customer service.
                    </div>
                </div>
            </div>

            {/* Second Section */}
            <div className='w-full bg-white rounded-lg shadow-lg flex flex-col md:flex-row-reverse'>
                <div className='w-full md:w-1/3 flex justify-center items-center animate-slideInRight'>
                    <img src={Customer} className=' md:h-40' alt="Customer Experience" />
                </div>
                <div className='w-full md:w-2/3 p-4 flex flex-col justify-center  animate-slideInLeft'>
                    <motion.h2
                        className="text-2xl font-semibold text-gray-800 mb-4"
                        initial={{ opacity: 0, x: -30 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                    >
                        Enhanced Customer Experience
                    </motion.h2>
                    <div className='text-gray-700'>
                        Customers love convenience. With QRapid, they can browse your menu, customize their orders, and pay—all from their smartphones. This not only reduces wait times but also makes the dining experience smoother and more enjoyable, encouraging repeat visits.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Whyus;
