export const Welcome = 'Welcome to QRapid, a forward-thinking digital solution dedicated to transforming the dining experience. We are passionate about leveraging technology to enhance restaurant operations, making them smoother, faster, and more efficient with our ecosystem. Our journey began with a simple yet powerful idea: to revolutionize the way people order food and interact with restaurants.'
export const Story = ' QRapid was founded by a group of friends who shared a love for good food and a keen interest in technology. We often found ourselves discussing the inefficiencies we observed in restaurants—long waiting times, order errors, and the hassle of manual billing. We realized there had to be a better way, one that could benefit both restaurants and their customers.             Our co-founders, Ashish, Vipul, and Rajat, combined their expertise in technology, business, and customer experience to create a solution that addresses these pain points.'

export const Commitment = `
  <div style="font-family: Arial, sans-serif; line-height: 1.6;">
    <p>At QRapid, we’re constantly innovating to keep you ahead in the fast-paced tech world.</p>
    
    <h3><strong>👉Dual Focus:</strong></h3>
    <p>Our solutions cater to both diners and restaurants, ensuring a seamless and rewarding experience for everyone.</p>
    
    <h3><strong>👉Uncompromising Quality:</strong></h3>
    <p>From top-tier software to exceptional support, quality is our priority.</p>
    
    <h3><strong>👉Join Us:</strong></h3>
    <p>Whether you're a restaurant owner or a diner, QRapid has something special for you. Let’s transform the dining experience together!</p>
    
  </div>
`;

export const qrRapidSpecial = `
  <div class="qr-rapid-highlight">
    <h1>QRapid: Revolutionizing Dining with Seamless QR Code Menus!</h1>
    <ul>
      <li><strong>Instant Access:</strong> Scan and view menus instantly.</li>
      <li><strong>Real-Time Updates:</strong> Freshness and accuracy with every order.</li>
      <li><strong>Error-Free Orders:</strong> Minimized mistakes with digital precision.</li>
      <li><strong>Enhanced Experience:</strong> Faster, smarter, and more engaging dining.</li>
    </ul>
  </div>
`;


