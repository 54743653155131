import React from 'react'
import { FaPaperPlane } from 'react-icons/fa'

function Openings(props) {
    return (
        <div className="p-6 bg-gray-50 rounded-lg shadow-md">
            <div className="flex justify-center items-center space-x-4 mb-4">
                <i className="text-2xl text-green-500" >{props.icon}</i>
                <h3 className="text-2xl font-semibold text-gray-800">{props.heading}</h3>
            </div>
            <div className='text-left'>
                <p className="text-gray-700 mb-4"><strong>Location:</strong>Mumbai</p>
                <p className="text-gray-600">
                    <span className="font-semibold"><strong>Responsibilities:</strong></span> {props.info}
                </p>
            </div>
            <div className='mt-4'>
                <a href="mailto:contact@qrapid.io" className="inline-block bg-green-500 text-white px-6 py-3 rounded-lg font-semibold text-lg hover:bg-blue-600 transition duration-300">
                    Apply Now <FaPaperPlane className="inline ml-2" />
                </a>

            </div>

        </div>
    )
}

export default Openings
