// src/ContactUs.js
import React from 'react';
import { motion } from 'framer-motion';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const ContactUs = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="max-w-lg w-full p-8 bg-white rounded-lg shadow-lg">
        <motion.h1
          className="text-3xl font-bold mb-6 text-center text-green-800"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Contact Us
        </motion.h1>
        <p className="text-gray-600 mb-8 text-center">
          Please feel free to contact us with any questions, comments, or collaborations.
        </p>
        <div className="space-y-4">
          <motion.div
            className="flex items-center space-x-3 p-4 bg-green-50 rounded-lg shadow-md"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <FaPhoneAlt className="text-green-500 text-2xl" />
            <div className="flex-1">
              <p className="text-gray-700">9137997312</p>
            </div>
          </motion.div>
          <motion.div
            className="flex items-center space-x-3 p-4 bg-green-50 rounded-lg shadow-md"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <FaEnvelope className="text-green-500 text-2xl" />
            <div className="flex-1">
              <p className="text-gray-700">contact@qrapid.io</p>
            </div>
          </motion.div>
          <motion.div
            className="flex items-center space-x-3 p-4 bg-green-50 rounded-lg shadow-md"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <FaMapMarkerAlt className="text-green-500 text-2xl" />
            <div className="flex-1">
              <p className="text-gray-700">Mumbai</p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
