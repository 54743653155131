import React, { useState } from "react";
import { FaUtensils, FaQrcode, FaGift } from "react-icons/fa";
import logo from "../img/logo.png";
import bg2 from "../img/bg2.avif";
import PartnerRestaurant from "../component/partner";
// import VisionMission from "./mission";
import RewardProgram from "../component/rewards";
import u1 from "../img/u1.jpg";
import u2 from "../img/u2.jpg";
import u3 from "../img/u3.jpg";
import { Link } from "react-router-dom";

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-white py-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center px-4">
        <Link to="/" className="flex items-center space-x-2">
          <img src={logo} alt="QRapid Logo" className="h-8 w-8" />
          <div className="text-xl font-extrabold text-green-600">QRapid</div>
        </Link>

        <nav className="hidden md:flex space-x-4">
        <Link to="/" className="text-gray-700 pt-2 font-bold hover:text-blue-500 transition duration-300">Home</Link>
        <Link to="/about" className="text-gray-700 pt-2 font-bold hover:text-blue-500 transition duration-300">About</Link>
          <Link to="/contact" className="text-gray-700 pt-2 font-bold hover:text-blue-500 transition duration-300">Contact</Link>
          <Link to="/career" className="text-gray-700 pt-2 font-bold hover:text-blue-500 transition duration-300">Career</Link>
        <Link to="/whyus" className="text-gray-700 pt-2 font-bold hover:text-blue-500 transition duration-300"> Why Us?</Link>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSddH4C3AIqsydo-vCeEGxH6sEWwVx2trE1OVmjq-Lontb621w/viewform?pli=1&pli=1" target="_blank" rel="noopener noreferrer">
            <button className="px-4 py-2 bg-green-500 text-white font-bold rounded-xl hover:bg-green-600 transition duration-300">For Restaurant</button>
          </a>
        </nav>

        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="text-gray-700 focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>

        {/* Mobile Menu */}
        <div className={`md:hidden w-full transition-all duration-300 ease-in-out ${isMenuOpen ? 'block' : 'hidden'}`}>
          <nav className="flex flex-col space-y-2 mt-2">
            <Link to="/" onClick={() => setIsMenuOpen(false)} className="text-gray-700 font-bold hover:text-blue-500 transition duration-300">Home</Link>
            <Link to="/about" onClick={() => setIsMenuOpen(false)} className="text-gray-700 font-bold hover:text-blue-500 transition duration-300">About</Link>
            <Link to="/contact" onClick={() => setIsMenuOpen(false)} className="text-gray-700 font-bold hover:text-blue-500 transition duration-300">Contact</Link>
            <Link to="/career" onClick={() => setIsMenuOpen(false)} className="text-gray-700 font-bold hover:text-blue-500 transition duration-300">Career</Link>
            <Link to="/whyus" onClick={() => setIsMenuOpen(false)} className="text-gray-700 font-bold hover:text-blue-500 transition duration-300">Why Us?</Link>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSddH4C3AIqsydo-vCeEGxH6sEWwVx2trE1OVmjq-Lontb621w/viewform?pli=1&pli=1" target="_blank" rel="noopener noreferrer">
              <button className="px-4 py-2 bg-green-500 text-white font-bold rounded hover:bg-green-600 transition duration-300">For Restaurant</button>
            </a>
          </nav>
        </div>
      </div>
    </header>
  );
};

export const Footer = () => {
  return (
    <footer className="bg-green-500 text-white py-8 mt-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Company Logo and Information */}
          <div className="flex flex-col items-center lg:items-start">
            <div className="animate-bounce mb-4">
              <img src={logo} alt="QRapid Logo" className="h-16 w-16" />
            </div>
            <div className="text-center lg:text-left">
              <h3 className="text-lg font-bold">About QRapid</h3>
              <p className="mt-2">QRapid is committed to delivering cutting-edge solutions and exceptional service. Our goal is to innovate and lead in our industry.</p>
              <div className="mt-4">
                <h4 className="font-bold">Location</h4>
                <p>Mumbai, Maharashtra</p>
              </div>
              <div className="mt-2">
                <h4 className="font-bold">Contact Us</h4>
                <a href="mailto:contact@qrapid.io" className="hover:text-blue-400 transition duration-300">contact@qrapid.io</a>
              </div>
            </div>
          </div>

          {/* Navigation Links */}
          <div className="space-y-2 text-center lg:text-left">
            <h3 className="text-lg font-bold">Organization</h3>
            <nav className="flex flex-col space-y-2">
              <Link to="/" className="hover:text-blue-400 transition duration-300">Home</Link>
              <Link to="/about" className="hover:text-blue-400 transition duration-300">About</Link>
              {/* <Link to="/mission" className="hover:text-blue-400 transition duration-300">Vision & Mission</Link> */}
              <Link to="/partners" className="hover:text-blue-400 transition duration-300">Partners</Link>
              <Link to="/contact" className="hover:text-blue-400 transition duration-300">Contact Us</Link>
              <Link to="/policy" className="hover:text-blue-400 transition duration-300">Privacy Policy</Link>
              <Link to="/faq" className="hover:text-blue-400 transition duration-300">FAQ</Link>
              <Link to="/terms" className="hover:text-blue-400 transition duration-300">Terms & Conditions</Link>
            </nav>
          </div>

          {/* Social Media Links */}
          <div className="space-y-2 text-center lg:text-left">
            <h3 className="text-lg font-bold">Follow Us</h3>
            <nav className="flex flex-col space-y-2">
              <a href="https://www.facebook.com/profile.php?id=61561308016185&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400 transition duration-300">Facebook</a>
              <a href="https://www.instagram.com/qrapid.io/" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400 transition duration-300">Instagram</a>
              <a href="https://in.linkedin.com/company/qrapidnow?trk=public_profile_topcard-current-company" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400 transition duration-300">LinkedIn</a>
              <a href="https://www.youtube.com/@qrapidnow" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400 transition duration-300">YouTube</a>
            </nav>
          </div>
        </div>

        <div className="border-t border-green-700 mt-8 pt-4 text-center text-sm">
          <p>© QRapid All Rights Reserved 2024</p>
        </div>
      </div>
    </footer>
  );
};

const LandingPage = () => {
  return (
    <div className="font-sans bg-gray-100">
      {/* Header */}
      <Header />

      {/* Hero Section with Background Image */}
      <main className="relative">
        <section
          className="relative text-center py-24 text-white bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${bg2})` }}
        >
          {/* Black Overlay */}
          <div className="absolute inset-0 bg-black opacity-60 z-0"></div>

          {/* Content */}
          <div className="relative z-10 px-4">
            <h1 className="text-4xl md:text-5xl font-extrabold mb-4 text-white drop-shadow-md animate-fadeIn">
              QR Menus & <span className="text-green-400">Discounts</span> Await <br /> Go Scan Now!
            </h1>
            <p className="text-lg md:text-xl mb-10 text-white drop-shadow-md animate-slideIn">
              Scan QR codes at your favorite restaurants <br /> for easy access to digital menus, exclusive offers, and rewards.
            </p>
            <a href="mailto:contact@qrapid.io">
              <button className="mt-8 px-6 py-3 bg-white text-green-600 font-semibold rounded-lg hover:bg-gray-100 transition duration-300 shadow-md animate-bounce">
                Connect With Us
              </button>
            </a>
          </div>
        </section>

        {/* Vision & Mission Section */}
        {/* <VisionMission /> */}

        {/* How It Works Section */}
        <section className="py-12">
          <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">How It Works?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white shadow-md p-6 rounded-lg text-center hover:shadow-lg transition duration-300">
              <FaUtensils className="text-blue-500 text-4xl md:text-5xl mx-auto mb-4 animate-pulse" />
              <div className="text-xl md:text-2xl font-semibold">Step 1</div>
              <p className="text-gray-600 mt-4">Visit a participating restaurant.</p>
            </div>
            <div className="bg-white shadow-md p-6 rounded-lg text-center hover:shadow-lg transition duration-300">
              <FaQrcode className="text-green-500 text-4xl md:text-5xl mx-auto mb-4 animate-bounce" />
              <div className="text-xl md:text-2xl font-semibold">Step 2</div>
              <p className="text-gray-600 mt-4">Scan the QR code on the table.</p>
            </div>
            <div className="bg-white shadow-md p-6 rounded-lg text-center hover:shadow-lg transition duration-300">
              <FaGift className="text-yellow-500 text-4xl md:text-5xl mx-auto mb-4 animate-pulse" />
              <div className="text-xl md:text-2xl font-semibold">Step 3</div>
              <p className="text-gray-600 mt-4">Browse the digital menu, order, and earn rewards.</p>
            </div>
          </div>
        </section>
        <PartnerRestaurant />

        {/* Testimonials Section */}
        <section className="py-12 bg-gray-50 text-black rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold mb-8 text-center">What Our Users Are Saying</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <p className="text-lg mb-4">"QRapid has transformed the way I dine out. Scanning QR codes is so convenient, and the rewards make it even better!"</p>
              <div className="flex items-center space-x-4">
                <img src={u1} alt="User 1" className="h-12 w-12 rounded-full" />
                <div>
                  <h4 className="text-xl font-semibold">Roshan Kamble</h4>
                  <p className="text-sm text-gray-600">Food Lover</p>
                </div>
              </div>
            </div>
            <div className="bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <p className="text-lg mb-4">"I love how easy it is to access digital menus and get special offers just by scanning a QR code. QRapid is amazing!"</p>
              <div className="flex items-center space-x-4">
                <img src={u2} alt="User 2" className="h-12 w-12 rounded-full" />
                <div>
                  <h4 className="text-xl font-semibold">Amit Das</h4>
                  <p className="text-sm text-gray-600">Frequent Diner</p>
                </div>
              </div>
            </div>
            <div className="bg-white text-gray-800 p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <p className="text-lg mb-4">"QRapid makes dining out so much fun! The loyalty points and birthday rewards are a fantastic touch."</p>
              <div className="flex items-center space-x-4">
                <img src={u3} alt="User 3" className="h-12 w-12 rounded-full" />
                <div>
                  <h4 className="text-xl font-semibold">Yashesh Shah</h4>
                  <p className="text-sm text-gray-600">Gourmet Enthusiast</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Call-to-Action Section */}
        <section className="py-12 text-center bg-blue-50 rounded-lg">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">Are You a Restaurant Owner?</h2>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSddH4C3AIqsydo-vCeEGxH6sEWwVx2trE1OVmjq-Lontb621w/viewform?pli=1&pli=1" target="_blank">
            <button className="px-8 py-4 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 transition duration-300 shadow-lg animate-fadeInUp">
              Partner with Us
            </button>
          </a>
        </section>
        <RewardProgram />

        {/* Footer */}
        <Footer />
      </main>
    </div>
  );
};

export default LandingPage;
