// src/PrivacyPolicy.js
import React from 'react';
import { motion } from 'framer-motion';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
      <div className="max-w-3xl w-full bg-white rounded-lg shadow-lg p-6">
        <motion.h1
          className="text-3xl font-bold mb-6 text-gray-800"
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Privacy Policy
        </motion.h1>
        <p className="text-gray-600 mb-6">Last Updated: 10th June 2024</p>
        <p className="text-gray-700 mb-4">
          At QRapid, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you use our services via our website, mobile application, and physical interactions at partner restaurants. By accessing or using our services, you agree to the collection and use of your information in accordance with this policy.
        </p>

        <section className="mb-6">
          <motion.h2
            className="text-2xl font-semibold text-gray-800 mb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
          >
            1. Information We Collect
          </motion.h2>
          <div className="mb-4">
            <motion.h3
              className="text-xl font-semibold text-gray-800 mb-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              1.1 Personal Information:
            </motion.h3>
            <ul className="list-disc list-inside text-gray-700">
              <li>Contact Details: Name, email address, phone number.</li>
              <li>Account Details: Username, password.</li>
              <li>Payment Information: Credit card details, billing address.</li>
              <li>Restaurant Interaction: Orders placed, loyalty points, feedback.</li>
            </ul>
          </div>
          <div>
            <motion.h3
              className="text-xl font-semibold text-gray-800 mb-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              1.2 Non-Personal Information:
            </motion.h3>
            <ul className="list-disc list-inside text-gray-700">
              <li>Usage Data: Browser type, device type, pages visited, time spent on pages, and other diagnostic data.</li>
              <li>Cookies and Tracking Technologies: We use cookies, beacons, tags, and scripts to collect and track information and to improve and analyze our services.</li>
            </ul>
          </div>
        </section>

        <section className="mb-6">
          <motion.h2
            className="text-2xl font-semibold text-gray-800 mb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
          >
            2. How We Use Your Information
          </motion.h2>
          <div className="mb-4">
            <motion.h3
              className="text-xl font-semibold text-gray-800 mb-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              2.1 To Provide and Maintain Services:
            </motion.h3>
            <ul className="list-disc list-inside text-gray-700">
              <li>Process orders and payments.</li>
              <li>Manage loyalty points and rewards.</li>
              <li>Provide customer support.</li>
            </ul>
          </div>
          <div className="mb-4">
            <motion.h3
              className="text-xl font-semibold text-gray-800 mb-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              2.2 To Improve Our Services:
            </motion.h3>
            <ul className="list-disc list-inside text-gray-700">
              <li>Analyze usage to improve website/app performance.</li>
              <li>Personalize user experience based on preferences and interactions.</li>
            </ul>
          </div>
          <div className="mb-4">
            <motion.h3
              className="text-xl font-semibold text-gray-800 mb-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              2.3 Communication:
            </motion.h3>
            <ul className="list-disc list-inside text-gray-700">
              <li>Send updates, newsletters, promotional materials, and other information.</li>
              <li>Notify you about changes to our services.</li>
            </ul>
          </div>
          <div>
            <motion.h3
              className="text-xl font-semibold text-gray-800 mb-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              2.4 Legal and Security Purposes:
            </motion.h3>
            <ul className="list-disc list-inside text-gray-700">
              <li>Comply with legal obligations and protect our rights.</li>
              <li>Detect, prevent, and address technical issues and fraud.</li>
            </ul>
          </div>
        </section>

        <section className="mb-6">
          <motion.h2
            className="text-2xl font-semibold text-gray-800 mb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
          >
            3. Information Sharing and Disclosure
          </motion.h2>
          <div className="mb-4">
            <motion.h3
              className="text-xl font-semibold text-gray-800 mb-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              3.1 With Service Providers:
            </motion.h3>
            <p className="text-gray-700">
              We may share your information with third-party vendors who perform services on our behalf, such as payment processing, data analysis, email delivery, and customer service.
            </p>
          </div>
          <div className="mb-4">
            <motion.h3
              className="text-xl font-semibold text-gray-800 mb-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              3.2 For Business Transfers:
            </motion.h3>
            <p className="text-gray-700">
              In the event of a merger, acquisition, or asset sale, your information may be transferred. We will provide notice before your information is transferred and becomes subject to a different privacy policy.
            </p>
          </div>
          <div>
            <motion.h3
              className="text-xl font-semibold text-gray-800 mb-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              3.3 Legal Requirements:
            </motion.h3>
            <p className="text-gray-700">
              We may disclose your information if required by law or in response to valid requests by public authorities.
            </p>
          </div>
        </section>

        <section className="mb-6">
          <motion.h2
            className="text-2xl font-semibold text-gray-800 mb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
          >
            4. Data Security
          </motion.h2>
          <p className="text-gray-700 mb-4">
            We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, use, alteration, and disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee its absolute security.
          </p>
        </section>

        <section className="mb-6">
          <motion.h2
            className="text-2xl font-semibold text-gray-800 mb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
          >
            5. Your Data Protection Rights
          </motion.h2>
          <div className="mb-4">
            <motion.h3
              className="text-xl font-semibold text-gray-800 mb-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              5.1 Access and Correction:
            </motion.h3>
            <p className="text-gray-700">
              You have the right to access, update, or correct your personal information.
            </p>
          </div>
          <div className="mb-4">
            <motion.h3
              className="text-xl font-semibold text-gray-800 mb-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              5.2 Deletion:
            </motion.h3>
            <p className="text-gray-700">
              You can request the deletion of your personal data, subject to certain exceptions.
            </p>
          </div>
          <div className="mb-4">
            <motion.h3
              className="text-xl font-semibold text-gray-800 mb-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              5.3 Objection and Restriction:
            </motion.h3>
            <p className="text-gray-700">
              You may object to or request the restriction of processing your personal information.
            </p>
          </div>
          <div className="mb-4">
            <motion.h3
              className="text-xl font-semibold text-gray-800 mb-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              5.4 Data Portability:
            </motion.h3>
            <p className="text-gray-700">
              You have the right to receive a copy of your personal data in a structured, commonly used, and machine-readable format.
            </p>
          </div>
        </section>

        <section>
          <motion.h2
            className="text-2xl font-semibold text-gray-800 mb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
          >
            6. Changes to This Privacy Policy
          </motion.h2>
          <p className="text-gray-700 mb-4">
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website and app. You are advised to review this Privacy Policy periodically for any changes.
          </p>
        </section>

        <section>
          <motion.h2
            className="text-2xl font-semibold text-gray-800 mb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
          >
            7. Contact Us
          </motion.h2>
          <p className="text-gray-700 mb-2">
            If you have any questions about this Privacy Policy, please contact us at:
          </p>
          <p className="text-gray-700 mb-2">QRapid: Mumbai</p>
          <p className="text-gray-700 mb-2">Email: <a href="mailto:contact@qrapid.io" className="text-blue-500 hover:underline">contact@qrapid.io</a></p>
          <p className="text-gray-700">Phone: <a href="tel:9137997312" className="text-blue-500 hover:underline">9137997312</a></p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
