import logo from "./logo.svg";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import LandingPage, { Footer, Header } from "./pages/landing";
import AboutUs from "./pages/aboutus";
import ContactUs from "./pages/contact";
import FAQ from "./component/faq";
import Careerss from "./pages/career";
import PartnerRestaurant from "./component/partner";
import PrivacyPolicy from "./component/policy";
import Whyus from "./pages/Whyus";

function App() {
  return (
    // <div className="App">
    //  <LandingPage/>
    // </div>
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/whyus"
            element={
              <>
                <Header />
                <Whyus />
                <Footer />
              </>
            }
          />
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/about"
            element={
              <>
                <Header />
                <AboutUs />
                <Footer />
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <Header />
                <ContactUs />
                <Footer />
              </>
            }
          />
          <Route
            path="/career"
            element={
              <>
                <Header />
                <Careerss />
                <Footer />
              </>
            }
          />
          <Route
            path="/faq"
            element={
              <>
                <Header />
                <FAQ />
                <Footer />
              </>
            }
          />
          <Route
            path="/partners"
            element={
              <>
                <Header />
                <PartnerRestaurant />
                <Footer />
              </>
            }
          />
          <Route
            path="/policy"
            element={
              <>
                <Header />
                <PrivacyPolicy />
                <Footer />
              </>
            }
          />

          {/* Add more routes here as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
