import React from 'react';
import { useInView } from 'react-intersection-observer';

export function About(props) {
    const isHtml = /<\/?[a-z][\s\S]*>/i.test(props.info);
    const { ref: textRef, inView: textInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: imageRef, inView: imageInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <section className="mb-12">
            <div className="flex flex-col md:flex-row items-center justify-center p-8">
                {/* Text Section */}
                <div
                    ref={textRef}
                    className={`max-w-lg text-center md:text-left space-y-8 transition-transform duration-1000 ${textInView ? 'animate-slideInLeft' : 'opacity-0 translate-x-[-100%]'
                        }`}
                >
                    <h2 className="text-3xl font-bold text-gray-800 animate-slideInUp">
                        {props.title}
                    </h2>
                    {isHtml ? (
                        <div className="text-gray-600 animate-fadeIn delay-500" dangerouslySetInnerHTML={{ __html: props.info }} />
                    ) : (
                        <p className="text-gray-600 animate-fadeIn delay-500">
                            {props.info}
                        </p>
                    )}
                </div>

                {/* Image Section */}
                <div
                    ref={imageRef}
                    className={`mt-8 w-full max-w-xs md:w-auto md:mt-0 md:ml-10 transition-transform duration-1000 ${imageInView ? 'animate-slideInRight' : 'opacity-0 translate-x-[100%]'
                        }`}
                >
                    <img
                        src={props.img}
                        alt="Description"
                        className="rounded-md p-5 shadow-lg w-full max-w-xs md:max-w-md lg:max-w-lg object-cover animate-zoomIn"
                    />
                </div>
            </div>
        </section>
    );
}

export function AboutReverse(props) {
    const isHtml = /<\/?[a-z][\s\S]*>/i.test(props.info);
    const { ref: textRef, inView: textInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: imageRef, inView: imageInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <section className="mb-12">
            <div className="flex flex-col md:flex-row gap-y-8 md:gap-x-8 items-center justify-center p-8">
                {/* Image Section */}
                <div
                    ref={imageRef}
                    className={`w-full p-8 max-w-xs md:w-auto transition-transform duration-1000 ${imageInView ? 'animate-slideInLeft' : 'opacity-0 translate-x-[100%]'
                        }`}
                >
                    <img
                        src={props.img}
                        alt="Description"
                        className="rounded-md shadow-lg w-full max-w-xs md:max-w-md lg:max-w-lg object-cover animate-zoomIn"
                    />
                </div>

                {/* Text Section */}
                <div
                    ref={textRef}
                    className={`max-w-lg text-center md:text-right space-y-8 transition-transform duration-1000 ${textInView ? 'animate-slideInRight' : 'opacity-0 translate-x-[-100%]'
                        }`}
                >
                    <h2 className="text-3xl font-bold text-center text-gray-800 animate-slideInUp">
                        {props.title}
                    </h2>
                    {isHtml ? (
                        <div className="text-gray-600 text-left animate-fadeIn delay-500" dangerouslySetInnerHTML={{ __html: props.info }} />
                    ) : (
                        <p className="text-gray-600 text-left animate-fadeIn delay-500">
                            {props.info}
                        </p>
                    )}
                </div>
            </div>
        </section>
    );
}
