import React from "react";
import i1 from "../img/4.png"
import i2 from "../img/5.png"
import i3 from "../img/6.png"
import i4 from "../img/7.png"
import i5 from "../img/8.png"
import i6 from "../img/10.webp"
import { Link } from "react-router-dom";

const restaurants = [
  {
    name: "The Lucky Restaurants",
    location: "Ghatkopar",
    image: i1,
    url:'https://g.co/kgs/PxawRKG'
  },
  {
    name: "Neo Welcome",
    location: "Kharghar",
    image: i2,
    url:'https://g.co/kgs/DnkrCpX'
  },
  {
    name: "Asian Restaurant",
    location: "Worli",
    image: i3,
    url:'https://g.co/kgs/JjP2nNp'
  },
  {
    name: "Namkeen Restaurant",
    location: "Marine Lines",
    image: i4,
    url:''
  },
  {
    name: "Neo Welcome",
    location: "Colaba",
    image: i5,
    url:"https://g.co/kgs/bFxcHr7"
  },
  {
    name: "Your  Restaurant",
    location: "",
    image: i6,
    url:"https://docs.google.com/forms/d/e/1FAIpQLSddH4C3AIqsydo-vCeEGxH6sEWwVx2trE1OVmjq-Lontb621w/viewform?pli=1&pli=1://docs.google.com/forms/d/e/1FAIpQLSeoQoLjusSTt4tAP_Yzl1aEOrnwcRbtMQ-tnBGAx8vjOtauVw/viewform"
  },
  // Add more restaurant objects as needed
];

const PartnerRestaurant = () => {
  return (
    <section className="py-12 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">
          Our Restaurant Partners
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {restaurants.map((restaurant, index) => (
            <div
              key={index}
              className="bg-white shadow-md rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300"
            >
              <img
                src={restaurant.image}
                alt={restaurant.name}
                className="w-full h-48 object-cover animate-fadeIn"
              />
              <div className="p-6 text-center">
                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                  {restaurant.name}
                </h3>
                <p className="text-gray-600">{restaurant.location}</p>
               { index== 5 ? <Link to={restaurant.url}><button className="mt-4 px-6 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition duration-300 shadow-md animate-bounce">
                  Apply Free Demo
                </button>
               </Link>  :<Link to={restaurant.url}><button className="mt-4 px-6 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition duration-300 shadow-md animate-bounce">
                View Details
                </button></Link> }
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PartnerRestaurant;
