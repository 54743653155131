// src/AboutUs.js
import React from 'react';
import { motion } from 'framer-motion';
import { About, AboutReverse } from '../component/About'
import { Welcome, Story, Commitment,qrRapidSpecial } from '../Constants'
import logo from '../img/logo.png'
import storyImg from '../img/About/Ashish.jpg'
import TableCustomer from '../img/About/TableCustomer.jpg'


const AboutUs = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className=" w-full p-8 bg-white rounded-lg shadow-lg">
        <motion.h1
          className="text-4xl font-bold mb-6 text-center text-gray-800"
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          About Us
        </motion.h1>

        <div className=''>
          <About title='' info={Welcome} img={logo} />
          <AboutReverse title='Our Story' info={Story} img={storyImg} />
          <About title={`QRapid's Commitment`} info={Commitment} img={TableCustomer} />
          <AboutReverse title='What makes QRapid different ?' info={qrRapidSpecial} img={logo} />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;