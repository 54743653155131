// src/Careers.js
import React from 'react';
import { FaCloud, FaCamera, FaUsers, FaMapMarkerAlt, FaPaperPlane } from 'react-icons/fa';
import { motion } from 'framer-motion';
import Openings from '../component/Career'

const Careerss = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
      <div className=" w-full bg-white rounded-lg shadow-lg p-8">
        <motion.h1
          className="text-4xl font-bold mb-6 text-gray-800 text-center"
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Careers at QRapid
        </motion.h1>
        <motion.p
          className="text-gray-700 mb-6 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          🚀 Embark on an exciting journey with QRapid, where we’re redefining the dining experience through cutting-edge technology. If you’re passionate about innovation and eager to make a difference, we want you on our team!
        </motion.p>

        <section className="mb-8">
          <motion.h2
            className="text-3xl font-semibold text-gray-800 mb-4 text-center"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Why Work With Us?
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="flex items-center space-x-4 p-4 bg-gray-50 rounded-lg shadow-md">
              <FaUsers className="text-3xl text-green-500" />
              <div>
                <h3 className="text-xl font-semibold text-green-800">Innovative Environment</h3>
                <p className="text-gray-600">Be part of a forward-thinking team that’s shaping the future of the restaurant industry.</p>
              </div>
            </div>
            <div className="flex items-center space-x-4 p-4 bg-gray-50 rounded-lg shadow-md">
              <FaCloud className="text-3xl text-green-500" />
              <div>
                <h3 className="text-xl font-semibold text-green-800">Growth Opportunities</h3>
                <p className="text-gray-600">We offer extensive opportunities for professional growth and advancement in a dynamic and evolving field.</p>
              </div>
            </div>
            <div className="flex items-center space-x-4 p-4 bg-gray-50 rounded-lg shadow-md">
              <FaCamera className="text-3xl text-green-500" />
              <div>
                <h3 className="text-xl font-semibold text-green-800">Collaborative Culture</h3>
                <p className="text-gray-600">Join a supportive and inclusive work environment where your ideas are valued, and collaboration is key.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-8">
          <motion.h2
            className="text-3xl font-semibold text-green-800 mb-4 text-center"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Current Openings
          </motion.h2>
          <div className="space-y-6">
            <Openings icon={<FaMapMarkerAlt />} heading="Developer" info="Design and implement Web and App solutions for our restaurant platform, manage cloud infrastructure." />
            <Openings icon={<FaCamera />} heading="Social Media Content Creator" info="Create engaging content for our social media platforms, including graphics, videos, and written posts. Collaborate with the marketing team to develop content strategies and campaigns. Be the social face of our company." />
            <Openings icon={<FaUsers />} heading="Join Our Sales Team!" info="Onboarding restaurant partners to use QRapid." />
          </div>
        </section>

        <section className="text-center">
          <motion.h2
            className="text-3xl font-semibold text-green-800 mb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Interested in Joining Us?
          </motion.h2>
          <p className="text-gray-700 mb-6">Interested candidates, please send your resume to <a href="mailto:contact@qrapid.io" className="text-blue-500 hover:underline">contact@qrapid.io</a>. Let’s create memorable dining experiences together!</p>
          <a href="mailto:contact@qrapid.io" className="inline-block bg-green-500 text-white px-6 py-3 rounded-lg font-semibold text-lg hover:bg-blue-600 transition duration-300">
            Apply Now <FaPaperPlane className="inline ml-2" />
          </a>
        </section>
      </div>
    </div>
  );
};

export default Careerss;
